@import './abstracts/_mixins';
@import './abstracts/_variables';
@import './components/_button';
@import './components/_title';

#footerPayment {
    background-color: var(--toc-white);
    overflow-x: auto;
}

.footer {
    @include color(--toc-white, none);

    #footerReinsurance {
        background-color: var(--toc-orange);
        justify-content: center;
    }

    #footerNavigationElements {
        background-color: var(--toc-brown);
    }

    #footerConditions {
        background-color: var(--toc-medium-brown);
    }

    #footerCopyrights {
        background-color: var(--toc-brown);
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    &__payment {
        padding: 2rem 0;
        margin-bottom: 0;

        picture {
            display: flex;
        }

        &__border {
            @include flex-row();
            justify-content: center;
            gap: 3rem;

            picture {
                gap: 2rem;

                span {
                    &::before {
                        content: " ";
                        display: block;
                        position: relative;
                        height: 100%;
                        border-left: 1px solid var(--toc-brown);
                    }
                }
            }

            &__images {
                display: flex;
                gap: 2rem;
            }
        }
    }

    &__reinsurance {
        @include flex-row-justify(space-between);
        padding-top: 1rem;
        padding-bottom: 1rem;
        margin-bottom: 0;
        overflow-x: auto;

        &>li {
            @include flex-row-justify(center);
            min-width: 270px;
            color: var(--toc-white);
            font-size: 1.3rem;
            font-weight: bold;
            white-space: pre-line;

            & img {
                width: 80px
            }
        }
    }

    &__navigation {
        padding: 3rem 0;

        &__link {
            &>li {
                @include flex-column();
                @include color(--toc-white, none);
                margin-bottom: 0.5rem;

                &.stores-modal-call--js {
                    cursor: pointer;
                    &:hover{
                        text-decoration: underline;
                    }
                }

                .__title {
                    font-size: 2rem;
                    text-transform: uppercase;
                    font-weight: 500;
                    color: var(--toc-white);
                }

                &:nth-child(1) {
                    &>span {
                        padding-bottom: 1rem;
                        position: relative;
                    }

                    &::after {
                        content: " ";
                        width: 30%;
                        height: 4px;
                        background-color: var(--toc-orange);
                    }
                }

                &:nth-child(2) {
                    margin-top: 1rem;
                }

                &>a {
                    @include color(--toc-white, none);
                }
            }

            &__contact--tel {
                a {
                    @include button-cta(noug);
                    position: relative;
                    width: 220px;

                    &::before {
                        @include icons('Line Awesome Free', "\f095", 2rem, var(--toc-white));
                    }

                    &::after {
                        content: 'Appel non surtaxé';
                        position: absolute;
                        display: flex;
                        bottom: -2rem;
                        font-size: 1rem;
                        text-transform: initial;
                        font-weight: 100;
                        right: 3rem;
                    }
                }
            }
        }
    }

    &__conditions {
        @include flex-row-justify(space-between);
        margin-bottom: 0;
        padding-top: 1rem;
        padding-bottom: 1rem;
        text-align: center;

        &>li>a {
            color: var(--toc-white);
        }
    }

    &__copyrights {
        color: var(--toc-orange);
    }

    @media (max-width: $breakpoint-lg) {
        &>section {
            padding: 0;
        }

        &__reinsurance {
            @include flex-row-justify(flex-start)
        }

        &__payment {
            row-gap: 2rem;

            img {
                width: 100%;
            }

            &__border {
                @include flex-column();
                gap: 1rem;
                justify-content: center;

                picture {
                    gap: 0;

                    span {
                        &::before {
                           display: none;
                        }
                    }
                }

                &__images {
                    justify-content: space-evenly;
                    gap: 0;
                }
            }
        }

        &__conditions {
            @include flex-column();
            padding: 2rem;
            gap: 0.5rem;
            align-items: baseline;
            text-align: center;
        }

        #footerCopyrights {
            padding-bottom: 0;
            gap: 3rem;
        }

        &__copyrights {
            padding: 2rem;
            background: var(--toc-dark-brown);
            text-align: center;
        }
    }

    @media (max-width: $breakpoint-md) {
        &__navigation {
            row-gap: 2rem;

            &__link {
                &>li:nth-child(1)>span::after {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 0;
                }

                & .chevron-up {
                    &::after {
                        @include icons($fontAwesomeFree, $chevron-up, 2rem, var(--toc-orange));
                    }
                }

                & .chevron-down {
                    &::after {
                        @include icons($fontAwesomeFree, $chevron-down, 2rem, var(--toc-orange));
                    }
                }

                &.hide-content-sm>li:nth-child(n+2) {
                    display: none;
                }
            }
        }
    }
}